import slugify from 'slugify';
import i18n from '@/i18n';
import { apolloClient } from '@/apollo';
import { restClient as axios } from '@/editor/axios';
import GET_USE_CASE_MAP from '@/graphql/GetUseCaseMap.gql';

export function sortSeasonalBackwards(a, b) {
  if (a.categories?.length) return Number.MAX_SAFE_INTEGER;
  if (b.categories?.length) return Number.MIN_SAFE_INTEGER;

  if (a.favorite && b.favorite) return 0;

  if (a.favorite) return Number.MIN_SAFE_INTEGER;
  if (b.favorite) return Number.MAX_SAFE_INTEGER;

  return Number(a?.popularity ?? 0) - Number(b?.popularity ?? 0);
}

export default {
  namespaced: true,
  state: {
    useCaseLoading: false,
    useCaseMap: null,
    extraDetailsMap: {},
    recommendedTactics: [],
  },
  getters: {
    useCases: (state) => {
      return state.useCaseMap;
    },
    localizedUseCase: (state, getters) => (useCaseId) => {
      if (!state.useCaseMap?.[useCaseId]) return null;

      return {
        ...state.useCaseMap[useCaseId],
        image: getters.useCaseImageById(useCaseId),
        name: getters.useCaseNameByIdAndLocale(useCaseId, i18n.locale),
        description: getters.useCaseDescriptionByIdAndLocale(useCaseId, i18n.locale),
      };
    },
    useCaseImageById: (state) => (useCaseId) => {
      if (!state.useCaseMap?.[useCaseId]) return null;

      return state.useCaseMap[useCaseId].svgPreviews[0];
    },
    useCaseNameByIdAndLocale: (state) => (useCaseId, locale) => {
      if (!state.useCaseMap?.[useCaseId]) return null;

      return state.useCaseMap[useCaseId].name[locale];
    },
    useCaseDescriptionByIdAndLocale: (state) => (useCaseId, locale) => {
      if (!state.useCaseMap?.[useCaseId]) return null;

      if (locale === 'en') return state.useCaseMap[useCaseId].subHeadline;

      return state.useCaseMap[useCaseId].segment.hu;
    },
    extraDetailsByUseCaseId: (state) => (useCaseId) => {
      return state.extraDetailsMap[useCaseId].sort(sortSeasonalBackwards);
    },
    isUseCaseLoading: (state) => {
      return state.useCaseLoading;
    },
    recommendedUseCases(state, getters) {
      return state.recommendedTactics.map((tactic) => getters.localizedUseCase(tactic._id));
    },
    sortedByScore(_, getters) {
      const list = Object.values(getters.useCases);

      list.sort((a, b) => {
        const aScore = Number(a.value3 || 0);
        const bScore = Number(b.value3 || 0);
        return bScore - aScore;
      });

      return list;
    },
  },
  actions: {
    async fetchUseCases({ state, commit }) {
      commit('setLoading', true);

      if (state.useCaseMap) {
        commit('setLoading', false);
        return state.useCaseMap;
      }

      const {
        data: { useCaseMap },
      } = await apolloClient.query({
        query: GET_USE_CASE_MAP,
      });
      Object.keys(useCaseMap).forEach((useCaseId) => {
        if (useCaseMap[useCaseId].difficulty !== 'low') {
          delete useCaseMap[useCaseId];
          return;
        }
        useCaseMap[useCaseId].slug = slugify(
          `${useCaseMap[useCaseId].name.en.toLowerCase()}-${useCaseId}`,
        );
      });

      commit('setUseCaseMap', useCaseMap);
      commit('setLoading', false);
    },
    async fetchExtraDetailsByUseCaseId({ state, commit }, useCaseId) {
      commit('setLoading', true);

      if (state.extraDetailsMap[useCaseId]) {
        commit('setLoading', false);
        return state.extraDetailsMap[useCaseId];
      }

      const templatesIds = state.useCaseMap[useCaseId].templates;
      const formattedTemplateIds = templatesIds.join(',');
      if (!formattedTemplateIds) {
        commit('setUseCaseExtraDetails', { extraDetails: [], useCaseId });
        commit('setLoading', false);
        return;
      }
      const { data } = await axios.get('/use-case/templates', {
        params: { templateIds: formattedTemplateIds, detailed: '1' },
      });

      commit('setUseCaseExtraDetails', { extraDetails: data, useCaseId });
      commit('setLoading', false);
    },
    async fetchRecommendedUseCases({ state, dispatch, rootState }) {
      await dispatch('fetchRecommendedTactics', null, { root: true });
      state.recommendedTactics = rootState.recommendedTactics;
    },
    async init({ dispatch }, useCaseId) {
      const actions = [dispatch('fetchUseCases'), dispatch('fetchRecommendedUseCases')];

      if (useCaseId) {
        await Promise.all(actions);
        await dispatch('fetchExtraDetailsByUseCaseId', useCaseId);
        return;
      }

      await Promise.all(actions);
    },
  },
  mutations: {
    setUseCaseMap(state, useCaseMap) {
      state.useCaseMap = useCaseMap;
    },
    setLoading(state, loading) {
      state.useCaseLoading = loading;
    },
    setUseCaseExtraDetails(state, { extraDetails, useCaseId }) {
      state.extraDetailsMap[useCaseId] = extraDetails;
    },
  },
};
