const moment = require('moment');
const {
  FREEMIUM_PLAN_NAMES,
  NON_FREEMIUM_PLAN_NAMES,
  DEMO_PLAN_NAME,
  BELSO_PLAN_NAME,
  OLD_PLAN_NAMES,
  INVALID_SKUES,
  NOT_UPGRADEABLE_PLAN_NAMES,
  FREEMIUM_PLANS,
} = require('../plans');

const freemiumPackages = [...FREEMIUM_PLAN_NAMES, DEMO_PLAN_NAME].map((name) => name.toLowerCase());
const notFreemiumPackages = [...NON_FREEMIUM_PLAN_NAMES, BELSO_PLAN_NAME].map((name) =>
  name.toLowerCase(),
);
const oldPackages = OLD_PLAN_NAMES.map((name) => name.toLowerCase());

const invalidSkues = INVALID_SKUES;

const notUpgradeablePackages = NOT_UPGRADEABLE_PLAN_NAMES;

const fromString = (sku, isPackage = true) => {
  if (!isPackage) {
    const details = sku.split('-');
    return {
      language: details.length > 1 ? details[0] : 'HU',
      name: details[1] || details[0],
      period: undefined,
    };
  }

  // eslint-disable-next-line no-unused-vars
  const [_, language, name, periodStr] = sku.split('-');

  return {
    language,
    name,
    period: Number(periodStr),
  };
};

const isEnterpriseOrMaster = (planName) => {
  return (planName || '').toUpperCase().match(/MASTER|ENTERPRISE/) !== null;
};

const toString = ({ name, language, period }) => {
  return `Package-${language}-${name}-${period}`;
};

const _isCustomWithRegex = (name) => {
  return (name || '').toUpperCase().match(/MASTER|ENTERPRISE|MANAGED_REFERRAL|AGENCY\_/) !== null;
};

const _isNonPayingWithRegex = (name) => {
  if (_isCustomWithRegex(name)) return false;
  return (name || '').toUpperCase().match(/FREE|TRIAL|DEMO|BELSO/) !== null;
};

const isNonPayingPackage = (packageSKU) => {
  return _isNonPayingWithRegex(packageSKU);
};

const isNonPayingPlan = (plan) => {
  const planName = (plan || '').toUpperCase();
  return _isNonPayingWithRegex(planName);
};

const isFreemiumPackage = (packageName) => {
  packageName = (packageName || '').toLowerCase();
  return freemiumPackages.includes(packageName) || packageName.includes('master');
};

const isFreemiumSKU = (packageSKU) => {
  const details = fromString(packageSKU);
  return isFreemiumPackage(details.name);
};

const isNotFreemiumPackage = (packageName, paying = false) => {
  packageName = (packageName || '').toLowerCase();
  const packages = paying ? notFreemiumPackages.slice(1) : notFreemiumPackages;
  return packages.includes(packageName);
};

const isStandardPlan = (packageName) => {
  packageName = (packageName || '').toUpperCase();
  if (_isCustomWithRegex(packageName)) return false;
  if (packageName.match(/DEMO|BELSO/) !== null) return false;
  return isFreemiumPackage(packageName) || isNotFreemiumPackage(packageName);
};

const isCustomPackage = (packageName) => {
  packageName = (packageName || '').toUpperCase();
  return _isCustomWithRegex(packageName);
};

const _getPeriodIntoName = (language, from, till) => {
  const format = language === 'HU' ? 'YYYY. MM. DD.' : 'DD/MM/YYYY';
  return `${moment.utc(from).format(format)} - ${moment.utc(till).format(format)}`;
};

const getPlainPlanName = (packageName) => {
  const plain = packageName
    .toUpperCase()
    .replace('_', ' ')
    .replace('(OLD)', '')
    .replace(/^(ENTERPRISE|MASTER|MANAGED_REFERRAL)(.*)+/, '$1');

  if (isStandardPlan(packageName) && isFreemiumPackage(packageName)) {
    return FREEMIUM_PLANS.find((plan) => plan.name === plain)?.displayName?.toUpperCase() ?? plain;
  }

  return plain;
};

const getProductNameBySku = (sku) => {
  const { name: packageName } = fromString(sku);
  return getPlainPlanName(packageName);
};

const getOrderProductName = ({
  sku,
  from = null,
  till = null,
  quantity = 1,
  defaultName = null,
}) => {
  if (!sku.includes('Package-')) {
    return defaultName ?? sku;
  }

  const { name, period, language } = fromString(sku);
  const plan = getPlainPlanName(name).replace(/^(AGENCY)(.*)+/, '$1');

  let periodString = ' ';

  if (period === 12) {
    periodString += language === 'HU' ? `(${1 * quantity} év)` : `(${1 * quantity} year)`;
  } else {
    periodString +=
      language === 'HU'
        ? `(${period * quantity} hónap)`
        : `(${period * quantity} ${period * quantity > 1 ? 'months' : 'month'})`;
  }

  if (from && till) {
    periodString += ` (${_getPeriodIntoName(language, from, till)})`;
  }

  if (isCustomPackage(name)) {
    const customSuffix = name
      .toUpperCase()
      .replace(/MANAGED_REFERRAL/, '')
      .replace(/(ENTERPRISE|MASTER|AGENCY)_/, '')
      .replace(/_/g, '-');
    const customSuffixTag = customSuffix ? ` (${customSuffix})` : '';
    return language === 'HU'
      ? `${plan} csomag${customSuffixTag}${periodString}`
      : `${plan} plan${customSuffixTag}${periodString}`;
  }

  return language === 'HU' ? `${plan} csomag${periodString}` : `${plan} plan${periodString}`;
};

const isOldPackage = (packageName) => {
  if (packageName.toUpperCase().match(/\(OLD\)/) !== null) return true;
  return oldPackages.indexOf(packageName.toLowerCase()) !== -1;
};

const _getPlanIndexes = (from, to) => {
  let plans = [];
  if (isFreemiumPackage(from) && isFreemiumPackage(to)) {
    plans = freemiumPackages;
  }
  if (isNotFreemiumPackage(from) && isNotFreemiumPackage(to)) {
    plans = notFreemiumPackages;
  }

  const fromIndex = plans.findIndex((plan) => plan.toUpperCase() === from.toUpperCase());
  const toIndex = plans.findIndex((plan) => plan.toUpperCase() === to.toUpperCase());

  if (toIndex === -1 || fromIndex === -1) {
    throw new Error(`Invalid plan change from ${from} -> ${to} (plan could not be found by name)`);
  }

  return { fromIndex, toIndex };
};

const isDowngrade = (from, to) => {
  if (!from || !to) return false;
  if (from.toUpperCase() === to.toUpperCase()) return false;
  if (!isStandardPlan(from) || !isStandardPlan(to)) return false;
  if (isNonPayingPackage(from)) return false;
  const { fromIndex, toIndex } = _getPlanIndexes(from, to);

  return fromIndex > toIndex;
};

const isUpgrade = (from, to) => {
  if (!from || !to) return false;
  if (from.toUpperCase() === to.toUpperCase()) return false;
  if (!isStandardPlan(from) || !isStandardPlan(to)) return true;
  if (isNonPayingPackage(from)) return true;
  const { fromIndex, toIndex } = _getPlanIndexes(from, to);

  return fromIndex < toIndex;
};

module.exports = {
  freemiumPackages,
  notFreemiumPackages,
  invalidSkues,
  notUpgradeablePackages,
  fromString,
  toString,
  isNonPayingPackage,
  isNonPayingPlan,
  isFreemiumPackage,
  isFreemiumSKU,
  isNotFreemiumPackage,
  isStandardPlan,
  isCustomPackage,
  isEnterpriseOrMaster,
  getPlainPlanName,
  getProductNameBySku,
  getOrderProductName,
  isOldPackage,
  isDowngrade,
  isUpgrade,
};
