const { UNLIMITED_VALUE, FREE_PACKAGE_SKU, DEMO_PACKAGE_SKU } = require('./constants');

const freePlan = {
  group: 'FREE',
  sku: {
    monthly: FREE_PACKAGE_SKU,
  },
  name: 'FREE',
  displayName: 'Free',
  color: '#2cc898',
  details: {
    pageViews: 10000,
    domains: 1,
    campaigns: UNLIMITED_VALUE,
    prioritySupport: false,
    unbranded: false,
  },
  features: {},
  public: true,
};

const standardPlans = [
  freePlan,
  {
    group: 'ESSENTIAL',
    sku: {
      monthly: 'Package-EN-ESSENTIAL-1',
      annual: 'Package-EN-ESSENTIAL-12',
    },
    name: 'ESSENTIAL',
    displayName: 'Essential',
    color: '#62bdd5',
    details: {
      pageViews: 30000,
      domains: 2,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    public: false,
  },
  {
    group: 'ESSENTIAL',
    sku: {
      monthly: 'Package-EN-ESSENTIAL20-1',
      annual: 'Package-EN-ESSENTIAL20-12',
    },
    name: 'ESSENTIAL20',
    displayName: 'Essential 20',
    color: '#62bdd5',
    details: {
      pageViews: 20000,
      domains: 2,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'ESSENTIAL',
    sku: {
      monthly: 'Package-EN-ESSENTIAL30-1',
      annual: 'Package-EN-ESSENTIAL30-12',
    },
    name: 'ESSENTIAL30',
    displayName: 'Essential 30',
    color: '#62bdd5',
    details: {
      pageViews: 30000,
      domains: 2,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'ESSENTIAL',
    sku: {
      monthly: 'Package-EN-ESSENTIAL50-1',
      annual: 'Package-EN-ESSENTIAL50-12',
    },
    name: 'ESSENTIAL50',
    displayName: 'Essential 50',
    color: '#62bdd5',
    details: {
      pageViews: 50000,
      domains: 2,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'GROWTH',
    sku: {
      monthly: 'Package-EN-GROWTH-1',
      annual: 'Package-EN-GROWTH-12',
    },
    name: 'GROWTH',
    displayName: 'Growth',
    color: '#eb5a29',
    details: {
      pageViews: 100000,
      domains: 4,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    public: false,
  },
  {
    group: 'GROWTH',
    sku: {
      monthly: 'Package-EN-GROWTH100-1',
      annual: 'Package-EN-GROWTH100-12',
    },
    name: 'GROWTH100',
    displayName: 'Growth 100',
    color: '#eb5a29',
    details: {
      pageViews: 100000,
      domains: 4,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'GROWTH',
    sku: {
      monthly: 'Package-EN-GROWTH150-1',
      annual: 'Package-EN-GROWTH150-12',
    },
    name: 'GROWTH150',
    displayName: 'Growth 150',
    color: '#eb5a29',
    details: {
      pageViews: 150000,
      domains: 4,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'GROWTH',
    sku: {
      monthly: 'Package-EN-GROWTH250-1',
      annual: 'Package-EN-GROWTH250-12',
    },
    name: 'GROWTH250',
    displayName: 'Growth 250',
    color: '#eb5a29',
    details: {
      pageViews: 250000,
      domains: 4,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'PREMIUM',
    sku: {
      monthly: 'Package-EN-PREMIUM-1',
      annual: 'Package-EN-PREMIUM-12',
    },
    name: 'PREMIUM',
    displayName: 'Premium',
    color: '#ff9e31',
    details: {
      pageViews: 500000,
      domains: 10,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    public: false,
  },
  {
    group: 'PREMIUM',
    sku: {
      monthly: 'Package-EN-PREMIUM500-1',
      annual: 'Package-EN-PREMIUM500-12',
    },
    name: 'PREMIUM500',
    displayName: 'Premium 500',
    color: '#ff9e31',
    details: {
      pageViews: 500000,
      domains: 10,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'PREMIUM',
    sku: {
      monthly: 'Package-EN-PREMIUM750-1',
      annual: 'Package-EN-PREMIUM750-12',
    },
    name: 'PREMIUM750',
    displayName: 'Premium 750',
    color: '#ff9e31',
    details: {
      pageViews: 750000,
      domains: 10,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
  {
    group: 'PREMIUM',
    sku: {
      monthly: 'Package-EN-PREMIUM1M-1',
      annual: 'Package-EN-PREMIUM1M-12',
    },
    name: 'PREMIUM1M',
    displayName: 'Premium 1M',
    color: '#ff9e31',
    details: {
      pageViews: 1000000,
      domains: 10,
      campaigns: UNLIMITED_VALUE,
      prioritySupport: false,
      unbranded: true,
    },
    features: {},
    variant: true,
    public: true,
  },
];

const customPlan = {
  name: 'MASTER',
  displayName: 'Master',
  color: '#7954ea',
  details: {
    pageViewsString: '500,000 +',
    domains: UNLIMITED_VALUE,
    campaigns: UNLIMITED_VALUE,
    prioritySupport: true,
    unbranded: true,
  },
  features: {},
  public: true,
};

const demoPlan = {
  sku: {
    monthly: DEMO_PACKAGE_SKU,
  },
  name: 'DEMO',
  displayName: 'Demo',
  color: '#2cc898',
  details: {
    pageViews: 50000,
    domains: 10,
    campaigns: UNLIMITED_VALUE,
    prioritySupport: false,
    unbranded: false,
  },
  features: {},
  public: true,
};

const getSkues = () => {
  return [
    ...standardPlans.map((plan) => plan.sku?.monthly).filter((sku) => !!sku),
    ...standardPlans.map((plan) => plan.sku?.annual).filter((sku) => !!sku),
  ].filter((sku) => !!sku);
};

module.exports = {
  FREEMIUM_PLAN_NAMES: standardPlans.map((plan) => plan.name),
  FREEMIUM_PLAN_SKUES: getSkues(),
  FREEMIUM_VARIANT_PLAN_NAMES: standardPlans
    .filter((plan) => !!plan.variant)
    .map((plan) => plan.name),
  FREEMIUM_GROUP_PLAN_NAMES: [...standardPlans.map((plan) => plan.group)],
  FREEMIUM_PLANS: standardPlans,
  FREE_PLAN: freePlan,
  MASTER_PLAN: customPlan,
  DEMO_PLAN: demoPlan,
  DEMO_PLAN_NAME: demoPlan.name,
};
