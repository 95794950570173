function _getTracker(tracker) {
  return window.parent && window.parent[tracker] ? window.parent[tracker] : window[tracker];
}

function getHeap() {
  return _getTracker('heap');
}

function localLog(tracker, ...data) {
  console.warn(`[${tracker}]`, ...data);
}

function getIdentifier() {
  return window.om.store.getters.databaseId;
}

const DEFAULT_RETRY_AMOUNT = 3;
const DEFAULT_RETRY_TIMEOUT = 1000;

const setEditorVersion = () => {
  const EDITOR_VERSION = { OLD: 2, NEW: 3 };
  let editorVersion;
  let pathName = window.location.pathname;

  if (window.parent !== window) pathName = window.parent.location.pathname;

  if (pathName.includes('/edit/')) {
    editorVersion = EDITOR_VERSION.NEW;
  }

  return editorVersion;
};

function track(eventName, properties, retry = DEFAULT_RETRY_AMOUNT) {
  const editorVersion = setEditorVersion();
  if (editorVersion) {
    if (properties instanceof Object) {
      properties.editorVersion = editorVersion;
    } else if (!properties) {
      properties = { editorVersion };
    }
  }

  if (retry <= 0) {
    console.error('[Heap] retry count lower than 0', eventName, properties);
    return;
  }
  const heap = getHeap();
  if (!heap) {
    console.warn('[Heap] heap.js is not loaded', eventName, properties);
    setTimeout(track, DEFAULT_RETRY_TIMEOUT, eventName, properties, --retry);
    return;
  }
  if (!heap.identity) {
    console.warn('[Heap] identifier is missing', eventName, properties);
    const identifier = getIdentifier();
    if (!identifier) {
      setTimeout(track, DEFAULT_RETRY_TIMEOUT, eventName, properties, DEFAULT_RETRY_AMOUNT);
      return;
    }
    heap.identity = identifier;
  }
  heap.track(eventName, properties);
  console.log('[Heap] sent:', eventName, properties);
}

function trackIntercom(name, params = null) {
  const intercom = _getTracker('Intercom');
  if (intercom) {
    intercom('trackEvent', name, params);
  } else {
    localLog('Intercom', name);
  }
}

export { track, trackIntercom, getHeap };
