export const TEMPLATE_AUTHOR = 'TEMPLATE_AUTHOR';
export const ACER_CCDB = 'ACER_CCDB';
export const ATTENTIVE_V2 = 'ATTENTIVE_V2';
export const SPAM_PROTECTION_MENU = 'SPAM_PROTECTION_MENU';
export const JETFABRIC_EMBEDDED_AND_DC = 'JETFABRIC_EMBEDDED_AND_DC';
export const CONTROL_VARIANT_UI = 'CONTROL_VARIANT_UI';
export const PRODUCT_PAGE_OPTIMIZER = 'PRODUCT_PAGE_OPTIMIZER';
export const CAMPAIGN_SHARE = 'CAMPAIGN_SHARE';
export const CONTENT_BY_AI = 'CONTENT_BY_AI';
export const OPTIMONK_RECOMMENDER = 'OPTIMONK_RECOMMENDER';
export const SMART_AB_TEST = 'SMART_AB_TEST';
export const SAB_NEW_CHIP = 'SAB_NEW_CHIP';
export const MULTI_LIST_CAMPAIGN = 'MULTI_LIST_CAMPAIGN';
export const WIZARD_AB_TEST = 'WIZARD_AB_TEST';
export const FREQUENCY_RULE_V2 = 'FREQUENCY_RULE_V2';
export const VISUALIZED_MARGINS = 'VISUALIZED_MARGINS';
export const AUTO_PERSONALIZE = 'AUTO_PERSONALIZE';
export const REFERRAL_SOURCE = 'REFERRAL_SOURCE';

export const FEATURE_CONSTANTS = {
  TEMPLATE_AUTHOR,
  ACER_CCDB,
  ATTENTIVE_V2,
  JETFABRIC_EMBEDDED_AND_DC,
  CONTROL_VARIANT_UI,
  SMART_AB_TEST,
  MULTI_LIST_CAMPAIGN,
  WIZARD_AB_TEST,
  FREQUENCY_RULE_V2,
  VISUALIZED_MARGINS,
  REFERRAL_SOURCE,
};

export const isFeatureEnabled = (features, flag) => {
  return Array.isArray(features) ? features.includes(flag) : false;
};

export const isSpamProtectionMenuEnabled = (features) => {
  return isFeatureEnabled(features, SPAM_PROTECTION_MENU);
};

export const isPPOEnabled = (features) => {
  return isFeatureEnabled(features, PRODUCT_PAGE_OPTIMIZER);
};

export const hasCampaignShareFeature = (features) => {
  return isFeatureEnabled(features, CAMPAIGN_SHARE);
};

export const isGenerateWithAIEnabled = (features) => {
  return isFeatureEnabled(features, CONTENT_BY_AI);
};

export const isOptimonkRecommenderEnabled = (features) => {
  return isFeatureEnabled(features, OPTIMONK_RECOMMENDER);
};

export const hasSmartAbTestFeature = (features) => {
  return isFeatureEnabled(features, SMART_AB_TEST);
};

export const isNewFrequencyRuleEnabled = (features) => {
  return isFeatureEnabled(features, FREQUENCY_RULE_V2);
};

export const isAutoPersonalizeEnabled = (features) => {
  return isFeatureEnabled(features, AUTO_PERSONALIZE);
};
