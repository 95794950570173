import tinycolor from 'tinycolor2';
import { restClient as axios } from '@/editor/axios';

export default {
  namespaced: true,
  state: {
    contents: {},
    prevPreferredLanguage: null,
  },
  getters: {
    getContentsByBaseThemeId: (state) => (baseThemeId) => {
      return state.contents[baseThemeId] || {};
    },
  },
  mutations: {
    SET_CONTENTS(state, newContents) {
      state.contents = {
        ...state.contents,
        ...newContents,
      };
    },
    SET_PREV_PREFERRED_LANGUAGE(state, newLanguage) {
      state.prevPreferredLanguage = newLanguage;
    },
  },
  actions: {
    async preloadContents(
      { getters, commit, state },
      { queries, colors, baseThemeId, url = null },
    ) {
      if (Object.keys(getters.getContentsByBaseThemeId(baseThemeId)).length > 0 && !url) {
        return getters.getContentsByBaseThemeId(baseThemeId);
      }

      try {
        if (!Array.isArray(queries) || queries.length === 0) {
          throw new Error('Invalid queries array');
        }

        const promises = queries.map((query) => axios.request(query));
        const contents = await Promise.all(promises);

        const builtSet = {
          [baseThemeId]: { ...(state.contents[baseThemeId] || {}) },
        };

        contents.forEach((content, index) => {
          const hex = tinycolor(colors[index]).toHexString();
          builtSet[baseThemeId][hex] = content.data;
        });

        commit('SET_CONTENTS', builtSet);
      } catch (e) {
        console.error('Error fetching templates', e);
      }
    },
  },
};
