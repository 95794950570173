import store from '@/store';

const useCasePreloader = async (to, from, next) => {
  const useCaseParts = to.params?.slug?.split?.('-') || [];
  const useCaseId = useCaseParts.reverse()[0];
  await store.dispatch('useCase/init', /[abcdef0-9]{24}/.test(useCaseId) ? useCaseId : null);
  next();
};

export default {
  path: '/:userId/templates',
  alias: '/:userId/templates/',
  // use the "templates" pathname, not this if you want to navigate to the templates homepage
  name: 'template_selector',
  component: () => import('@/components/TemplateChooser/View.vue'),
  meta: { permission: ['normal', 'sub'], disableScrollTop: true },
  children: [
    {
      path: '',
      component: () => import('@/components/TemplateChooser/children/Home.vue'),
      name: 'templates',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
    },
    {
      path: '/:userId/templates/custom-templates',
      component: () => import('@/components/TemplateChooser/children/Listing.vue'),
      name: 'custom-templates',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
    },
    {
      path: '/:userId/templates/your-themes',
      component: () => import('@/components/TemplateChooser/children/Collection.vue'),
      name: 'your-themes-collection',
      meta: { permission: ['normal', 'sub'] },
      children: [
        {
          path: '/:userId/templates/your-themes/:slug',
          component: () => import('@/components/TemplateChooser/children/Listing.vue'),
          name: 'your-themes-templates',
          meta: { permission: ['normal', 'sub'], disableScrollTop: true },
        },
      ],
    },
    {
      path: '/:userId/templates/themes',
      component: () => import('@/components/TemplateChooser/children/BannersCollection.vue'),
      name: 'themes-collection',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      children: [
        {
          path: '/:userId/templates/themes/:slug',
          component: () => import('@/components/TemplateChooser/children/Listing.vue'),
          name: 'themes-templates',
          meta: { permission: ['normal', 'sub'], disableScrollTop: true },
          props: { rootMargin: '300px' },
        },
      ],
    },
    {
      path: '/:userId/templates/seasonal',
      component: () => import('@/components/TemplateChooser/children/BannersCollection.vue'),
      name: 'seasonal-collection',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      children: [
        {
          path: '/:userId/templates/seasonal/:slug',
          component: () => import('@/components/TemplateChooser/children/Listing.vue'),
          name: 'seasonal-templates',
          meta: { permission: ['normal', 'sub'], disableScrollTop: true },
        },
      ],
    },
    {
      path: '/:userId/templates/use-cases',
      component: () => import('@/components/TemplateChooser/children/UseCases.vue'),
      name: 'chooser-use-cases',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      children: [
        {
          path: '/:userId/templates/use-cases/:slug',
          component: () => import('@/components/TemplateChooser/children/Listing.vue'),
          name: 'chooser-use-case',
          meta: { permission: ['normal', 'sub'], disableScrollTop: true },
          beforeEnter: useCasePreloader,
        },
      ],
    },
    {
      path: '/:userId/templates/:type/:slug',
      component: () => import('@/components/TemplateChooser/children/Listing.vue'),
      name: 'chooser-other-listing',
      meta: { permission: ['normal', 'sub'], disableScrollTop: true },
      beforeEnter: useCasePreloader,
    },
    {
      path: '/:userId/templates/reseller-templates',
      component: () => import('@/components/TemplateChooser/children/Listing.vue'),
      name: 'reseller-templates',
      meta: { permission: ['sub'], disableScrollTop: true },
    },
  ],
};
